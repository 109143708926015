<template>
  <div></div>
</template>

<script>
export default {
  name: "Affilate",
  created() {
    let storage = window.localStorage
    storage.setItem('id', this.$route.params.id)
    this.$router.replace('/')
  }
}
</script>

<style scoped>

</style>
